<template>
  <div>
    <v-card>
      <v-tabs v-model="tab">
        <v-tab href="#tab-1">ຍອດຈັດຊື້</v-tab>
        <v-tab href="#tab-2">ຍອດເບີກອອກສາງ</v-tab>
        <v-tab href="#tab-3">ເອົາເຂົ້າສາງຄືນ</v-tab>
      </v-tabs>
      <!-- <hr /> -->

      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1">
          <v-card flat>
            <v-card-text>
              <Purchase />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>

      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-2">
          <v-card flat>
            <v-card-text>
              <OutboundWarehouse />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>

      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-3">
          <v-card flat>
            <v-card-text> <ReturnWarehouse /> </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import Purchase from "@views/warehouse/report/tabbar/purchase";
import OutboundWarehouse from "@views/warehouse/report/tabbar/outboundwarehouse";
import ReturnWarehouse from "@views/warehouse/report/tabbar/returnwarehouse";
import manage from "@/mixins/finance/index";
export default {
  mixins: [manage],
  components: {
    Purchase,
    OutboundWarehouse,
    ReturnWarehouse,
  },
  data() {
    return {
      tab: null,
    };
  },
  created() {
    if (this.$route.query.tab == "report-purchase") {
      this.tab = "tab-1";
    } else if (this.$route.query.tab == "report-export-product") {
      this.tab = "tab-2";
    } else if (this.$route.query.tab == "report-return-product") {
      this.tab = "tab-3";
    }
  },
  watch: {
    tab: function (value) {
      if (value == "tab-1") {
        this.$router
          .push({ name: "Warehouse-Report", query: { tab: "report-purchase" } })
          .catch(() => {});
      } else if (value == "tab-2") {
        this.$router
          .push({
            name: "Warehouse-Report",
            query: { tab: "report-export-product" },
          })
          .catch(() => {});
      } else if (value == "tab-3") {
        this.$router
          .push({
            name: "Warehouse-Report",
            query: { tab: "report-return-product" },
          })
          .catch(() => {});
      }
    },
  },
};
</script>

<style>
</style>
