<template>
  <div>
    <v-row justify="end">
      <v-col><h2>ສະຫຼຸບຍອດຂາຍ</h2></v-col>
      <!--
      <v-col class="al-end">
        <v-btn
          color="primary"
          @click="exportData()"
          :loading="exportLoading"
          :disabled="exportLoading"
          >Export</v-btn
        ></v-col
      >
-->
    </v-row>
    <!-- <v-row class="my-2" justify="end">
      <v-col><h2>ທັງໝົດ {{}}</h2></v-col>
    </v-row> -->
    <!-- <hr /> -->

    <v-row>
      <v-col>
        <v-menu
          v-model="start_menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="start_date"
              label="ເລີ່ມວັນທີ"
              readonly
              outlined
              v-bind="attrs"
              v-on="on"
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="start_date"
            @input="fetchData()"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-menu
          v-model="end_menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="end_date"
              label="ຫາວັນທີ"
              readonly
              outlined
              v-bind="attrs"
              v-on="on"
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="end_date"
            @input="fetchData()"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col>
        <v-text-field
          outlined
          dense
          clearable
          prepend-inner-icon="mdi-account-search-outline"
          label="ເລກບິນ,ຊື່ລູກຄ້າ,ເບີໂທ"
          type="text"
          v-model="search"
          @keyup.enter="Search()"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row class="mb-1">
      <v-col cols="2">
        <h4>ລວມບິນ: {{ Intl.NumberFormat().format(totalBill) }}</h4>
      </v-col>
      <v-col cols="2">
        <h4>ລວມສິນຄ້າ: {{ Intl.NumberFormat().format(sumTotalProduct) }}</h4>
      </v-col>

      <v-col>
        <h4>ລວມເງິນທັງໝົດ: {{ Intl.NumberFormat().format(sumTotalPrice) }}</h4>
      </v-col>
      <!--
      <v-col>
        <h4 class="primary--text">
          ເງິນສົດ: {{ Intl.NumberFormat().format(sumTotalCash) }}
        </h4>
      </v-col>
      <v-col>
        <h4 class="success--text">
          ທະນາຄານ: {{ Intl.NumberFormat().format(sumTotalBank) }}
        </h4>
      </v-col>
      <v-col>
        <h4 class="info--text">
          ສະໜັບສະໜຸນ: {{ Intl.NumberFormat().format(sumTotalSupport) }}
        </h4>
      </v-col>
      <v-col>
        <h4 class="error--text">
          ຕິດໜີ້: {{ Intl.NumberFormat().format(sumTotalDebt) }}
        </h4>
      </v-col>
      -->
      <!--
      <v-col>
        <h4 class="warning--text">
          ຄ່າມັດຈຳ: {{ Intl.NumberFormat().format(totalBondingFee()) }}
        </h4>
      </v-col>
-->
    </v-row>
    <!-- <hr /> -->
    <v-data-table
      :headers="headers"
      :items="purchases"
      :disable-pagination="true"
      hide-default-footer
      class="elevation-1"
      :loading="TableLoading"
      :disabled="TableLoading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:item.subtotal="{ item }">
        {{ Intl.NumberFormat().format(item.subtotal) }}
      </template>
      <template v-slot:item.discount_price="{ item }">
        {{ Intl.NumberFormat().format(item.discount_price) }}
      </template>
      <template v-slot:item.total="{ item }">
        {{ Intl.NumberFormat().format(item.total) }}
      </template>
      <!--
      <template v-slot:item.createBy="{ item }">
        {{ item.createBy.name }}
      </template>
      <template v-slot:item.customer="{ item }">
        {{ item.customer.name }}
      </template>
      -->
      <!--
      <template v-slot:item.team="{ item }">
        <div v-if="item.created_by_type">
          {{ item.created_by_type.name }}
        </div>
        <div>
          <span v-if="item.createBy.name" class="primary-color">{{
            item.createBy.name
          }}</span>
          <span v-for="(member, index) in item.createByMembers" :key="index">
            <span>, {{ member.name }} </span>
          </span>
        </div>
      </template>
      <template v-slot:item.invoice_status="{ item }">
        <v-chip color="success">{{ item.invoice_status }}</v-chip>
      </template>
      <template v-slot:item.payment_type="{ item }">
        <div :class="getPaymentTypeColor(item.payment_type)">
          {{ paymentType(item.payment_type) }}
        </div>
      </template>
-->
      <!--Action -->

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="viewInvoice(item)">mdi-eye</v-icon>
        <!--<v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
      </template>
    </v-data-table>
    <br />
    <template>
      <Pagination
        v-if="pagination.last_page > 1"
        :pagination="pagination"
        :offset="offset"
        @paginate="fetchData()"
      ></Pagination>
    </template>

    <ModalView v-if="purchaseDetail">
      <template @close="close">
        <v-card mdi-map-marker-circle :loading="loading" :disabled="loading">
          <v-card-title>
            <h4>ລາຍລະອຽດໃບບິນ</h4>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <div class="invoice">
                    ເລກບິນ: {{ purchaseDetail.purchase_number }}
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="invoice">
                    ວັນທີຈັດຊື້: {{ purchaseDetail.purchase_date }}
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <div class="invoice">
                    ປະເພດການຊຳລະ:
                    <span
                      :class="getPaymentTypeColor(purchaseDetail.payment_type)"
                      >{{ paymentType(purchaseDetail.payment_type) }}</span
                    >
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="invoice">
                    ຜູ້ຮັບຜິດຊອບ:
                    <span class="primary-color">{{
                      purchaseDetail.created_by
                    }}</span>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" v-if="purchaseDetail.purchaseBy">
                  <div class="invoice">
                    ຜູ້ຈັດຊື້:
                    <span class="primary-color">{{
                      purchaseDetail.purchaseBy
                    }}</span>
                  </div>
                </v-col>
                <v-col cols="6" v-if="purchaseDetail.supplier">
                  <div class="invoice">
                    Supplier: {{ purchaseDetail.supplier }}
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <div class="invoice">
                    ສະຖານະ
                    <v-chip color="success">{{ purchaseDetail.status }}</v-chip>
                  </div>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <h3>ລາຍລະອຽດສິນຄ້າ</h3>
                </v-col>
              </v-row>
              <v-card>
                <v-card-text>
                  <v-row>
                    <v-col cols="4">
                      <div class="customer">ລາຍການ</div>
                    </v-col>
                    <v-col cols="2">
                      <div class="customer">ຈຳນວນ</div>
                    </v-col>
                    <v-col cols="2">
                      <div class="customer">ລາຄາ</div>
                    </v-col>
                    <v-col cols="2">
                      <div class="customer">ລາຄາລວມ</div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <v-card
                v-for="(item, index) in purchaseDetail.purchseDetail"
                :key="index"
                outlined
              >
                <v-card-text>
                  <v-row>
                    <v-col cols="4">
                      <div class="customer">
                        {{ index + 1 }} {{ item.productName }}
                      </div>
                    </v-col>
                    <v-col cols="2">
                      <div class="customer">{{ item.amount }}</div>
                    </v-col>
                    <v-col cols="2">
                      <div class="customer">
                        {{ Intl.NumberFormat().format(item.purchase_price) }}
                      </div>
                    </v-col>
                    <v-col cols="2">
                      <div class="customer">
                        {{
                          Intl.NumberFormat().format(
                            item.purchase_price * item.amount
                          )
                        }}
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <br />
              <v-card outlined>
                <v-card-text>
                  <v-row>
                    <v-col cols="4">
                      <div class="customer">
                        ຈຳນວນລາຍການ: {{ purchaseDetail.countItem }}
                      </div>
                    </v-col>
                    <v-col cols="3"> </v-col>
                    <v-col cols="3">
                      <div class="customer">
                        ລາຄາລວມ:
                        {{ Intl.NumberFormat().format(purchaseDetail.total) }}
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-container>
            <br />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" class="mr-2" depressed @click="closeModal()">
                Close
              </v-btn>
              <!--
              <v-btn
                color="primary"
                depressed
                @click="confirmPaymentStatus('cash')"
              >
                ຊຳລະເງິນສົດ
              </v-btn>
              <v-btn
                color="success"
                class="mr-2"
                depressed
                @click="confirmPaymentStatus('bank')"
              >
                ຊຳລະທະນາຄານ
              </v-btn>
              <v-btn
                color="info"
                class="mr-2"
                depressed
                @click="confirmPaymentStatus('support')"
              >
                ສະໝັບສະໜູນ
              </v-btn>
              <v-btn
                color="warning"
                class="mr-2"
                depressed
                @click="confirmPaymentStatus('debt')"
              >
                ຕິດໜີ້
              </v-btn>
              -->
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </ModalView>
  </div>
</template>

<script>
import Pagination from "@/plugins/pagination/pagination";
import { GetOldValueOnInput } from "@/Helpers/GetValue";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      start_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      end_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      start_menu: false,
      end_menu: false,

      purchases: [],

      loading: false,
      exportLoading: false,
      TableLoading: false,
      purchaseDetail: [],
      invoices: [],
      invoiceDetailItem: [],
      users: [],
      selectedUser: "all",
      invoiceDetail: {},
      customerDetail: {},
      createdByDetail: {},
      //Sum Variable
      totalBill: 0,
      sumTotalPrice: 0,
      sumTotalProduct: 0,
      //Payment Type
      selectedPayment: "all",
      defaultValue: {
        id: "all",
        name: "ທັງໝົດ",
      },
      payment_Types: [
        {
          value: "all",
          name: "ທັງໝົດ",
        },
        {
          value: "cash",
          name: "ເງິນສົດ",
        },
        {
          value: "bank",
          name: "ທະນາຄານ",
        },
        {
          value: "support",
          name: "ສະໜັບສະໜູນ",
        },
        {
          value: "debt",
          name: "ຕິດໜີ້",
        },
      ],
      //Pagination
      offset: 12,
      pagination: {},
      per_page: 12,
      search: "",
      oldVal: "",
      headers: [
        {
          text: "ເລກບິນ",
          align: "start",
          sortable: false,
          value: "purchase_number",
        },
        // { text: "ຈຳນວນ", align: "center", value: "totalBottle" },
        { text: "ຜູ້ຈັດຊື້", value: "purchaseBy" },
        { text: "ຈຳນວລາຍການ", value: "countItem", align: "center" },
        { text: "ຜູ້ສະໜອງ", value: "supplier", sortable: false },
        // { text: "ສ່ວນຫຼຸດ", value: "discount_price", sortable: false },
        { text: "ລວມທັງໝົດ", value: "total" },
        { text: "ຊຳລະ", value: "payment_type", sortable: false },
        { text: "ວັນທີຈັດຊື້", value: "purchase_date" },
        { text: "ວັນທີຊຳລະ", value: "paymentDate" },
        // {
        //   text: "Created By",
        //   value: "created_by",
        //   align: "start",
        //   sortable: false,
        // },
        // { text: "ສະຖານະ", value: "invoice_status", align: "center" },
        { text: "", value: "actions", sortable: false, align: "center" },
      ],
      editedIndex: -1,

      invoiceHeader: [
        {
          text: "ລະຫັດຕຸກນ້ຳ",
          align: "start",
          sortable: false,
          value: "barcode",
        },
        { text: "ຊື່ລາຍການ", value: "invoiceDetailItem.product_name" },
        { text: "ລາຄາ", value: "price" },
      ],
    };
  },
  methods: {
    fetchData() {
      // var rolesUser = ["drivermanager", "driveruser", "driverandbranch"];
      (this.start_date);
      this.TableLoading = true;
      this.$admin
        .get("warehouse/report-purchase", {
          params: {
            startDate: this.start_date,
            endDate: this.end_date,
            page: this.pagination.current_page,
            per_page: this.per_page,
            search: this.search,
          },
        })
        .then((res) => {
          setTimeout(() => {
            this.purchases = res.data.purchases.data;
            this.pagination = res.data.purchases;
            this.totalBill = res.data.purchases.total;
            this.sumTotalPrice = res.data.sumTotalPrice;
            this.sumTotalProduct = res.data.sumTotalProduct;
            this.end_menu = false;
            this.TableLoading = false;
          }, 100);
        })
        .catch(() => {
          this.TableLoading = false;
          this.end_menu = false;
          this.start_menu = false;
          this.$store.commit("Toast_State", this.toast_error);
        });
    },
    //View Invoice
    viewInvoice(value) {
      this.purchaseDetail = value;
      this.$store.commit("modalView_State", true);
    },

    closeModal() {
      this.$store.commit("modalView_State", false);
    },
    paymentType(value) {
      if (value == "debt") return "ຕິດໜີ້";
      else if (value == "cash") return "ເງິນສົດ";
      else if (value == "bank") return "ທະນາຄານ";
      else if (value == "support") return "ສະໜັບສະໝູນ";
      else return;
    },
    getPaymentTypeColor(value) {
      if (value == "bank") return "success--text";
      else if (value == "cash") return "primary--text";
      else if (value == "debt") return "error--text";
      else if (value == "support") return "purple--text";
    },
    saleType(sale, price) {
      if (sale == "sale") return Number(price).toLocaleString();
      else if (sale == "free") return "Free";
    },
    getFreeStyle(sale) {
      if (sale == "free") return "primary-color";
    },
    totalPay(b, total) {
      return Number(b + total).toLocaleString();
    },
    saleByType(type) {
      if (type == "driver") {
        return "ລົດຂົນສົ່ງ";
      } else if (type == "branch") {
        return "ສາຂາ";
      }
    },
    //Export excel
    exportData() {
      this.exportLoading = true;
      this.$axios
        .post(
          "export/invoices",
          {
            startDate: this.start_date,
            endDate: this.end_date,
            payment_type: this.selectedPayment,
          },
          { responseType: "blob" }
        )
        .then((res) => {
          setTimeout(() => {
            const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
            const fileLink = document.createElement("a");
            fileLink.href = fileUrl;
            fileLink.setAttribute(
              "download",
              "ລາຍການຂາຍ " +
                "ປະຈຳວັນທີ " +
                this.start_date +
                " ຫາ " +
                this.end_date +
                ".xlsx"
            );
            document.body.appendChild(fileLink);
            fileLink.click();
            document.body.removeChild(fileLink);
            this.exportLoading = false;
          }, 100);
        })
        .catch(() => {
          this.exportLoading = false;
          this.$store.commit("Toast_State", this.toast_error);
        });
    },
    Search() {
      GetOldValueOnInput(this);
    },
  },
  watch: {
    search: function (value) {
      if (value == "") {
        this.fetchData();
      }
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style>
.al-end {
  text-align: end !important;
}
.invoice {
  font-size: 16px;
  color: #000000;
}
.primary-color {
  color: #00c1d2;
}
</style>
