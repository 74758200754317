<template>
  <div>
    <v-row justify="end">
      <v-col><h2>ສະຫຼຸບຍອດຂາຍ</h2></v-col>
      <!--
      <v-col class="al-end">
        <v-btn
          color="primary"
          @click="exportData()"
          :loading="exportLoading"
          :disabled="exportLoading"
          >Export</v-btn
        ></v-col
      >
-->
    </v-row>
    <!-- <v-row class="my-2" justify="end">
      <v-col><h2>ທັງໝົດ {{}}</h2></v-col>
    </v-row> -->
    <!-- <hr /> -->

    <v-row>
      <v-col>
        <v-menu
          v-model="start_menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="start_date"
              label="ເລີ່ມວັນທີ"
              readonly
              outlined
              v-bind="attrs"
              v-on="on"
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="start_date"
            @input="fetchData()"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-menu
          v-model="end_menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="end_date"
              label="ຫາວັນທີ"
              readonly
              outlined
              v-bind="attrs"
              v-on="on"
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="end_date"
            @input="fetchData()"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <!--
      <v-col class="d-flex">
        <v-select
          v-model="selectedPayment"
          :items="payment_Types"
          label="ປະເພດການຊຳລະ"
          item-text="name"
          item-value="value"
          dense
          outlined
          @input="fetchData()"
        ></v-select>
      </v-col>
      <v-col class="d-flex">
        <v-autocomplete
          v-model="selectedUser"
          :items="users"
          label="User"
          item-text="name"
          item-value="id"
          dense
          outlined
          @input="fetchData()"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-text-field
          outlined
          dense
          clearable
          prepend-inner-icon="mdi-account-search-outline"
          label="ເລກບິນ,ຊື່ລູກຄ້າ,ເບີໂທ"
          type="text"
          v-model="search"
          @keyup.enter="Search()"
        >
        </v-text-field>
      </v-col>
      -->
    </v-row>
    <v-row class="mb-1">
      <v-col cols="1">
        <h4>ລວມບິນ: {{ Intl.NumberFormat().format(totalBill) }}</h4>
      </v-col>
      <!-- <v-col cols="1">
        <h4>ລວມຕຸກນ້ຳ: {{ Intl.NumberFormat().format(sumTotalBottles) }}</h4>
      </v-col> -->
      <!--
      <v-col>
        <h4 class="warning--text">
          ຄ່າມັດຈຳ: {{ Intl.NumberFormat().format(totalBondingFee()) }}
        </h4>
      </v-col>
-->
    </v-row>
    <!-- <hr /> -->
    <v-data-table
      :headers="headers"
      :items="returnProducts"
      :disable-pagination="true"
      hide-default-footer
      class="elevation-1"
      :loading="TableLoading"
      :disabled="TableLoading"
      loading-text="Loading... Please wait"
    >
    </v-data-table>
    <br />
    <template>
      <Pagination
        v-if="pagination.last_page > 1"
        :pagination="pagination"
        :offset="offset"
        @paginate="fetchData()"
      ></Pagination>
    </template>
  </div>
</template>

<script>
import Pagination from "@/plugins/pagination/pagination";
import { GetOldValueOnInput } from "@/Helpers/GetValue";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      start_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      end_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      start_menu: false,
      end_menu: false,

      purchases: [],

      loading: false,
      exportLoading: false,
      TableLoading: false,
      returnProducts: [],

      //Sum Variable
      totalBill: 0,

      //Pagination
      offset: 12,
      pagination: {},
      per_page: 12,
      search: "",
      oldVal: "",
      headers: [
        {
          text: "ເລກບິນ",
          align: "start",
          sortable: false,
          value: "return_number",
        },
        { text: "ສິນຄ້າ", align: "center", value: "productName" },
        { text: "ຈຳນວນ", align: "center", value: "amount" },
        { text: "ຜູ້ສົ່ງຄືນ", value: "fromName" },
        { text: "ລາຍລະອຽດ", value: "note" },
        { text: "ວັນທີສົ່ງຄືນ", value: "return_date" },
        { text: "ຜູ້ຮັບຜິດຊອບ", value: "created_by", sortable: false },
        // {
        //   text: "Created By",
        //   value: "created_by",
        //   align: "start",
        //   sortable: false,
        // },
        // { text: "ສະຖານະ", value: "invoice_status", align: "center" },
        // { text: "", value: "actions", sortable: false, align: "center" },
      ],
    };
  },
  methods: {
    fetchData() {
      // var rolesUser = ["drivermanager", "driveruser", "driverandbranch"];
      this.TableLoading = true;
      this.$admin
        .get("warehouse/report-return-product", {
          startDate: this.start_date,
          endDate: this.end_date,
          page: this.pagination.current_page,
          per_page: this.per_page,
          search: this.search,
        })
        .then((res) => {
          setTimeout(() => {
            this.returnProducts = res.data.returnProducts.data;
            this.pagination = res.data.returnProducts;
            this.totalBill = res.data.returnProducts.total;
            // this.sumTotalPrice = res.data.sumTotalPrice;
            // this.sumTotalCash = res.data.sumTotalCash;
            // this.sumTotalDebt = res.data.sumTotalDebt;
            // this.sumTotalBank = res.data.sumTotalBank;
            // this.sumTotalSupport = res.data.sumTotalSupport;
            // this.sumTotalBottles = res.data.sumTotalBottles;
            this.start_menu = false;
            this.end_menu = false;
            this.TableLoading = false;
          }, 100);
        })
        .catch(() => {
          this.TableLoading = false;
          this.end_menu = false;
          this.start_menu = false;
          this.$store.commit("Toast_State", this.toast_error);
        });
    },

    //Export excel
    exportData() {
      this.exportLoading = true;
      this.$axios
        .post(
          "export/invoices",
          {
            startDate: this.start_date,
            endDate: this.end_date,
            payment_type: this.selectedPayment,
          },
          { responseType: "blob" }
        )
        .then((res) => {
          setTimeout(() => {
            const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
            const fileLink = document.createElement("a");
            fileLink.href = fileUrl;
            fileLink.setAttribute(
              "download",
              "ລາຍການຂາຍ " +
                "ປະຈຳວັນທີ " +
                this.start_date +
                " ຫາ " +
                this.end_date +
                ".xlsx"
            );
            document.body.appendChild(fileLink);
            fileLink.click();
            document.body.removeChild(fileLink);
            this.exportLoading = false;
          }, 100);
        })
        .catch(() => {
          this.exportLoading = false;
          this.$store.commit("Toast_State", this.toast_error);
        });
    },
    Search() {
      GetOldValueOnInput(this);
    },
  },
  watch: {
    search: function (value) {
      if (value == "") {
        this.fetchData();
      }
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style>
.al-end {
  text-align: end !important;
}
.invoice {
  font-size: 16px;
  color: #000000;
}
.primary-color {
  color: #00c1d2;
}
</style>
